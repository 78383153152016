<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box>

			<app-input-text notes="You can leave this blank to allow the system to auto-allocate a reference number." label="Reference" v-model="model.reference" :validation="$v.model.reference" placeholder="Enter reference" />
			<app-input-suggest label="Game" v-model="model.game" :validation="$v.model.game" api="bgg/game" image="image" placeholder="Select game" />
			<app-input-suggest label="Version" :disabled="!model.game" v-model="model.version" :validation="$v.model.version" api="bgg/version" :params="{ game: model.game }" image="image" placeholder="Select version" />
			<app-input-text label="Shelf" v-model="model.shelf" :validation="$v.model.shelf" placeholder="Enter shelf location" :maxlength="16" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: false,
				reference: '',
				game: 0,
				version: 0,
				shelf: ''
			}
		}

	},

	validations: {
		model: {
			game: {
				required
			}
		}
	}

}

</script>

<style scoped>

</style>